import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ActionLink from "../components/action-link"
import { graphql } from "gatsby"

const NewsPage = ({data}) => {
  return <Layout className="w-full lg:w-3/4 mx-auto px-8 lg:pr-16">
    <SEO title="Nieuws" />
    {
      data.allMarkdownRemark.edges.map(({node}) => {
        return <div>  
          <h3>{node.frontmatter.title}</h3>
          <p className="text-sm">{node.frontmatter.date}</p>
          <p>{node.excerpt}</p>
          <ActionLink to={`/news/${node.parent.name}`}>Lees meer ›</ActionLink>
          <hr />
        </div>   
      })
    }
  </Layout>
}

export default NewsPage

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          excerpt
          parent {
              ... on File {
                  name
              }
          }
        }
      }
    }
  }
`